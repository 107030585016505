import { VChip } from 'vuetify/lib/components/VChip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"justify-end"},[_c('cms-preset-select',{attrs:{"dense":"","value":_vm.currentPreset},on:{"input":_vm.onPresetSelect}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'preset-block-update', params: { preset: _vm.currentPreset, id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"ma-2 text-uppercase"},[_vm._v(" "+_vm._s(item.type.replace('_', ' '))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"to":{ name: 'preset-block-update', params: { preset: _vm.currentPreset, id: item.id } }}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Preset Blocks') },false))}
var staticRenderFns = []

export { render, staticRenderFns }