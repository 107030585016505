import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"justify-end"},[_c('preset-import-dialog',{on:{"onSuccess":_vm.reloadCollection},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var isOpen = ref.isOpen;
return [(_vm.canUpdate && _vm.canCreate)?_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","color":"primary","disabled":isOpen}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Import'))+" ")]):_vm._e()]}}])})],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('preset-export-dialog',{attrs:{"value":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","fab":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("download")])],1)]}}],null,true)})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('preset-action-delete',{attrs:{"value":item,"icon":""},on:{"onSuccess":_vm.reloadCollection}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Presets') },false))}
var staticRenderFns = []

export { render, staticRenderFns }