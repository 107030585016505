import { ref } from '@vue/composition-api'
import type { Preset } from '@futureecom/futureecom-js/dist/services/cms-service'

const tenancyPresets = ref<Record<string, { presets?: Preset[]; active?: string }>>({})

export const usePresets = (organisation: string, store: string) => {
  const getTenancyPresets = (): Preset[] | undefined => {
    return tenancyPresets.value[`${organisation}-${store}`]?.presets
  }

  const setTenancyPresets = (presets?: Preset[]) => {
    tenancyPresets.value = { ...tenancyPresets.value, [`${organisation}-${store}`]: { presets } }
  }

  const setActivePresetCode = (code: string) => {
    const presets = getTenancyPresets()
    tenancyPresets.value = { ...tenancyPresets.value, [`${organisation}-${store}`]: { presets, active: code } }
  }

  const getActivePresetCode = (): string => {
    return tenancyPresets.value[`${organisation}-${store}`]?.active || ''
  }

  const findPresetByCode = (code: string): Preset | undefined => {
    return (getTenancyPresets() || []).find((item) => item.code === code)
  }

  return {
    getTenancyPresets,
    setTenancyPresets,
    setActivePresetCode,
    getActivePresetCode,
    findPresetByCode
  }
}
